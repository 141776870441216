/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "mapbox-gl/src/css/mapbox-gl.css";

/* Custom CSS by Dimmy Marcelissen */
@import "theme/custom";


.experience-background-header {background-image: url("assets/tholen_app_header_experience.png");}
.inspiring-background-header {background-image: url("assets/tholen_app_header_inspiring.png");}
.education-background-header {background-image: url("assets/tholen_app_header_education.png");}
.informative-background-header {background-image: url("assets/tholen_app_header_informative.png");}
.general-background-header,
.search-background-header,
.favorite-background-header,
.privacy-background-header,
.route-background-header{background-image: url("assets/tholen_app_header_general.png");}

.d-block{
  display: block;
}

.cdk-overlay-pane{
  max-width:100vw!important;
}

mat-dialog-container {
  background: rgba(255,255,255,.85)!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}

.mat-dialog-actions{
  justify-content: center !important;
  button{
    background: $themeColorGeneral;

  }
}

.marker-map-overview.marker-map-detail.mapboxgl-marker.mapboxgl-marker-anchor-center {
  width: 37px;
  z-index: 2;
  height: 52px;
}


.info-message{
  position: absolute;
  left: auto;
  right: auto;
  bottom: 12px;
}

.point-cursor-icon{
  width: 20px;
  margin-right: 14px;
  margin-top: 6px;
}

.general-message-container{
  border-radius: 25px;
  border: 4px solid #f7f7f7;
  background: white;
  color: #7f7f7f;
  width:  50%;
  margin: 0 auto;
  font-family: Bree-Serif;
  font-weight: bold;
  p{
    font-size: 13pt!important;
    margin: 10px 0 0!important;
  }
}

.white-overlay{
  background: linear-gradient(to top, rgb(255 255 255), rgba(255, 255, 255, 0.2), transparent);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.white-overlay-to-left{
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, .4));
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.w-50{
  width: 50%;
}
