// Fonts
@font-face {
  src: url("../assets/fonts/BreeSerif-Regular.ttf");
  font-family: Bree-Serif;
}

@font-face {
  src: url("../assets/fonts/DJ5CTRIAL.ttf");
  font-family: Dear-Joe;
}

@font-face {
  src: url("../assets/fonts/PT_Sans-Narrow-Web-Regular.ttf");
  font-family: Sans-Narrow;
}

@font-face {
  src: url("../assets/fonts/OpenSans-Regular.ttf");
  font-family: Open-Sans;
}


// Variables
//$themeColorExperience: #6AB023;
$themeColorExperience: #60bb46;
$themeColorInspiring: #009EE0;
$themeColorEducation: #adadad;
$themeColorInformative: #F7A50E;
$themeColorGeneral: #00aeef;
$themeColorGeneralBackground: #bdebff;
$noteColor: #757474;
$subTitleColor: #8b8b8b;
$tabBackground: #f7f7f7;
$panelBackgroundColor: #d5d5d5;
$boxShadow: 0px 0px 10px 1px rgb(0 0 0 / 20%);
$filterShadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
$heightTabBar: 83px;
$heightHeader: 52px;

// Colors
.experience-bg-color{background-color: $themeColorExperience!important;}
.inspiring-bg-color{  background-color: $themeColorInspiring!important;}
.education-bg-color{  background-color: $themeColorEducation!important;}
.informative-bg-color{  background-color: $themeColorInformative!important;}
.general-bg-color,
.search-bg-color,
.favorite-bg-color,
.favorite-bg-color,
.route-bg-color{  background-color: $themeColorGeneral !important;}

.experience-border-color{border-color: $themeColorExperience!important;}
.inspiring-border-color{  border-color: $themeColorInspiring!important;}
.education-border-color{  border-color: $themeColorEducation!important;}
.informative-border-color{  border-color: $themeColorInformative!important;}
.general-border-color,
.search-border-color,
.favorite-border-color,
.route-border-color{  border-color: $themeColorGeneral !important;}

.experience-color{  color: $themeColorExperience;}
.inspiring-color{  color: $themeColorInspiring;}
.education-color{  color: $themeColorEducation;}
.informative-color{  color: $themeColorInformative;}
.general-color,
.search-color,
.favorite-color,
.route-color {  color: $themeColorGeneral;}
.color-white{color: white;}
.font-bold{
  font-weight: bold;
}
.o-hidden{
  overflow: hidden;
}

.op-0{
  opacity:0!important;
}

.op-1{
  opacity: 1!important;
}

.d-none{
  display: none;
}

.z-index-0{
  z-index: 0;
}
.z-index-5{
  z-index: 5;
}
.z-index-10{
  z-index: 10;
}

.marker-map-overview{
  background: url("../assets/tholen_app_logo_marker.png");
  width: 47px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: 100%;
}

app-tab-bar{
  position: fixed;
  width: 100%;
  height: 99px;
  bottom: 0;
  background: white;
  &.route-height{
    height: 123px;
  }
}

ion-tabs{
  border-top: 4px solid;
}

.custom-margin-bottom-tab-bar-route{
  margin-bottom: 123px;
}
.custom-margin-bottom-tab-bar{
  margin-bottom: 99px;
}

// Typography
p{
  line-height: 1.5;
  font-family: Open-Sans;
  text-align: left;
  margin-top: 1em;
  color: $subTitleColor;
  font-size: 11pt;
}
h1,
h2,
h3,
h4,
h5,
h6 { font-family: Bree-Serif; margin-top: 1em;}
.quote{font-family: Dear-Joe;}


// Utils | General
.clear{clear: both;}
.hidden{display: none!important;}
.border-top-0{border:none;}
.covert{opacity: 0;}


// Utils | Flex
.align-content-center{align-content: center;}


//Utils | Positions
.position-relative{position: relative;}
.position-absolute{position: absolute;}
.absolute-center-x{left: 0; right: 0;}


// Utils | Widths & Heights
.h-100{height: 100%;}
.h-200{height: 200%;}
.vh-100{height: 100vh;}
.w-100{width: 100%;}
.vh-75{height: 75vh;}


.main-height-custom{
  height: calc(100vh - 50vh);
  padding-top: 39px;
}

// Utils | Margins & Paddings
.ion-no-padding-top{padding-top: 0}
.ion-no-padding-right{padding-right: 0}
.ion-no-padding-bottom{padding-bottom: 0}
.ion-no-padding-left{padding-left: 0}
.ion-no-padding-x{padding-left: 0; padding-right: 0}
.ion-no-padding-y{padding-top: 0; padding-bottom: 0}

.ion-no-margin-top{margin-top: 0}
.ion-no-margin-right{margin-right: 0}
.ion-no-margin-bottom{margin-bottom: 0}
.ion-no-margin-left{margin-left: 0}
.ion-no-margin-x{margin-left: 0; margin-right: 0}
.ion-no-margin-y{margin-top: 0; margin-bottom: 0}

// Custom
app-drag-panel{
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
}

.scroll-y{
  overflow-y: scroll;
}

#fullIonSlides, #fullIonSlidesPoi{
  height: 245px;
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 5px!important;
    padding: .5rem;
    span{
      width: 18px!important;
      height: 18px!important;
      opacity: 1;
      background: white;
      box-shadow: $boxShadow
    }
  }
  &.experience-slides{
    span.swiper-pagination-bullet-active{
      background: $themeColorExperience !important;
    }
  }
  &.inspiring-slides{
    .swiper-pagination-bullet-active {
      background: $themeColorInspiring !important;
    }
  }
  &.education-slides{
    .swiper-pagination-bullet-active{
      background: $themeColorEducation !important;
    }
  }
  &.informative-slides{
    .swiper-pagination-bullet-active{
      background: $themeColorInformative !important;
    }
  }
  &.general-slides,
  &.search-slides,
  &.favorite-slides,
  &.route-slides{
    .swiper-pagination-bullet-active{
      background: $themeColorGeneral !important;
    }
  }
}

.background-white{
  background:white;
}

.container-content{
  margin: 0;
}

.d-none{
  display: none!important;
}

.detail-poi-dot{
  height: 10px;
  width: 10px;
  background: white;
  border: 1px solid $themeColorGeneral;
  border-radius: 50%;
}

// Custom Filters
.filter{
  //width: 45px;
  height: 45px;
  text-align: center;
  position: relative;
  z-index: 999999;
  margin-bottom: .5em;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  .filter-container{
    border-radius: 10px;
    padding: 0 10px;
    height: 45px;
    text-align: center;
    position: relative;
    z-index: 999999;
    //box-shadow: $boxShadow;
    border: 1px solid $panelBackgroundColor;
    margin-bottom: .5em;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    img{
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    p{
      font-family: Bree-Serif;
    }
  }
}

.poi-bullet{
  border-radius: 50%;
  border-style: solid;
  border-width: 7px;
  position: absolute;
  background: white;
  width: 30px;
  height: 30px;
  &.active{
    width: 50px;
    height: 50px;
    .poi-slide-number{
      display: block;
      color: white;
      font-size: 12pt;
    }

  }
  .poi-slide-number{
    display: none;
    color: white;
    font-size: 12pt;
  }
}

.flex-align-center{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.flex-align-right{
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-align-center-column{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filter-white-image{
  img{
    filter: brightness(0) invert(1);
  }
}

#poi-title{
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: calc($heightTabBar + 75px + 36px);
  text-align: center;
  z-index: 100;
  margin: 0 auto;
  figure{
    width: 100%;
    display: block;
    margin: 0 auto;
    height: 20px;
    z-index: 100;
    position: relative;
    img{
      -webkit-filter: drop-shadow($filterShadow);
      filter: drop-shadow($filterShadow);
      height: 100%;
    }
  }
  h1{
    border-radius: 15px;
    box-shadow: $boxShadow;
    //color: #666;
    background-color: white;
    display: inline-block;
    margin: 0 auto;
    //width: 100%;
    padding: 10px 15px;
    font-size: 14pt;
  }
}

.animation{
  transition: all .2s linear;
  &.active{
    opacity: 1;
  }
  opacity: 0;
}

.splash-screen{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
  z-index: 9998;
  background-color: transparent;
  transition: all .2s linear;
}

.overlay{
  transition: all .2s linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 55%;
  height: 100%;
  z-index: 10;
}

.filter-custom-margin{
  margin-left: 0px;
  position: relative;
  width: 45px;
}

.non-active{
  opacity: .5;
}

.search-filter-bar{
  ion-searchbar{
    .searchbar-input-container{
      text-align: left;
      box-shadow: none!important;
      --box-shadow: none!important;
      input{
        padding: 5px 0px !important;
      }
    }
  }
}

.current-location-marker{
  background: url("../assets/current-marker.png");
  width: 32px;
  height: 42px;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 100!important;
}

.marker-start-end{
  width: 33px;
  height: 31px;
}

#marker-start{
  background: url("../assets/tholen_app_logo_marker.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

#marker-end{
  background: url("../assets/tholen_app_logo_marker_end.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

@keyframes bulletToActiveroute {
  from {width: 25px; height: 25px; font-size:12px;}
  to {width: 35px; height: 35px; font-size:22px;}
}

@keyframes bulletToDeactiveroute {
  from {width: 35px; height: 35px; font-size:22px;}
  to {width: 25px; height: 25px; font-size:12px;}
}

.poi-marker{
  border-radius: 50%;
  border: 1px solid;
  background: white;
  width: 25px;
  height: 25px;
  color: white;
  font-weight: bold;
}

.poi-marker-active{
  width: 35px;
  height: 35px;
  font-size: 22px;
}

.animation-marker-active{
  animation:bulletToActiveroute .5s ;
}

.animation-marker-deactive{
  animation:bulletToDeactiveroute .5s ;
}



.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right{
  z-index: 0!important;
}
.logo-marker,
.marker-map-overview{
  max-width: 45px;
  .index{
    color: white;
    position: absolute;
    font-family: Bree-Serif;
    text-shadow: -1px 0 $noteColor, 0 1px $noteColor, 1px 0 $noteColor, 0 -1px $noteColor;
    &.large{
      left: 10px;
      font-size: 20pt;
    }
    &.medium{
      font-size: 19px;
      left: 8px;
      top: 5px;
    }
    &.small{
      font-size: 14px;
      left: 7px;
      top: 9px;
    }
  }
}
.logo-marker,{
  .index{&.large{
      top: -2px;
    }
    &.medium{
      font-size: 19px;
      left: 8px;
      top: 5px;
    }
    &.small{
      font-size: 14px;
      left: 7px;
      top: 9px;
    }
  }
}
.marker-map-overview{
  .index{
    &.large{
      top: 5px;
    }
    &.medium{
      top: 7px;
    }
    &.small{
      font-size: 14px;
      left: 7px;
      top: 9px;
    }
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets{
  left: 15.7% !important;
  width: 70%!important;
}

.img-fit{
  &.info{
    height: 270px;
  }
  &.poi{
    height: 50vh
  }
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
  }
}

.video-fit{
  &.info{
    height: 270px;
  }
  &.poi{
    height: 50vh
  }
  video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
  }
}

mat-progress-bar{
  width: 30%;
  margin-top: 16px;
  .mat-progress-bar-buffer{
    background: $themeColorExperience;
    &:after{
      background: $themeColorExperience;
    }
  }
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after{
  background: $themeColorGeneral!important;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer{
  background: $themeColorGeneralBackground!important;
}

.console{
  background: black;
  padding: 5px 10px;
  position: absolute;
  top: 460px;
  left: 0;
  right: 0;
  z-index: 999999999999999999999999999999999999;
  width: 100%;
  &.danger{
    color: #ff4343;
    font-size: 10pt;
  }
  &.success{
    color: limegreen;
  }
}
.mapboxgl-control-container{
  display: none;
}

div#filter-start-route {
  justify-content: center;
}
